<template>
  <tr :class="{'persons__row-inactive': props.item.status == 'inactive', 'persons__row-negatived': props.item.negatived_count > 0}">
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions>
    <td class="justify-left" :style="{cursor: 'pointer'}" @click="openPerson()">
      {{props.item.id}}
    </td>
    <td class="justify-left" :style="{cursor: config.mode != 'select' ? 'pointer' : ''}" @click="openPerson()">
      <span v-if="props.item.reference">{{ props.item.reference }} - </span>
      <strong>{{props.item.name}}</strong><br>
      <span>{{formatedGroups}}</span>
    </td>
    <td class="justify-left" :style="{cursor: config.mode != 'select' ? 'pointer' : ''}" @click="openPerson()">
      {{props.item.first_name}}
    </td>
    <td class="justify-left" :style="{cursor: config.mode != 'select' ? 'pointer' : ''}" @click="openPerson()">
      {{props.item.cpf_cnpj}}
    </td>
    <td class="justify-center" :style="{cursor: config.mode != 'select' ? 'pointer' : ''}" @click="openPerson()">
      <span v-if="props.item.status == 'active'" class="persons__status persons__status--active">
        Ativo
      </span>
      <span v-else class="persons__status persons__status--inactive">
        Inativo
      </span>
    </td>
  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'Persons',
    computed: {
      formatedGroups () {
        return this.props?.item?.groups?.map(group => (group.description))?.join(', ') || ''
      }
    },
    methods: {
      openPerson: function () {
        if (this.config.mode != 'select') {
          let route = this.config.config.urlDetails
          route.params['id'] = this.props.item.id
          this.$router.push(route)
        }
      }
    },
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>
<style scoped>
  td {
    padding: 0 5px !important;
  }
  th {
    padding: 0 5px !important;
  }
  .persons__row-inactive {
    background: rgb(209, 209, 209);
  }
  
  .persons__row-negatived {
    background: rgb(0, 0, 0);
    color: white !important;
  }
  .persons__row-negatived:hover {
    background: rgb(0, 0, 0);
    color: white !important;
  }
  .persons__status {
    border-width: 1px;
    border-style: solid;
    padding: 2px 5px;
  }
  .persons__status--active {
    color: #eceff1;
    background: rgb(4, 112, 0);
  }
  .persons__status--inactive {
    color: grey;
    background: #eceff1;
  }
</style>